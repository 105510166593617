import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import KTUtil from '@src/_metronic/_assets/js/util';

import { CustomerVehicle } from '@app/crud/customers/customer.type';
import { getStockCrvoInvoice } from '@app/crud/stock/stock.crud';

import { filterCustomerEvents } from '@app/pages/customers/Helpers/Events';

import toast from '@app/partials/content/Toast';

import LifeDataTable from '../LifeDatatable';

import './vehicleDatatable.scss';

interface Props {
    event: CustomerVehicle;
    activeTab: string;
    tab: string;
}
const VehicleDatatable = ({ event, activeTab, tab }: Props) => {
    const Intl = useIntl();
    const tabEvents = filterCustomerEvents(event?.events, tab);
    const mecTemplate = event?.registrationNumberDate
        ? dayjs(event?.registrationNumberDate, ['DD-MM-YYYY', 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD']).format('DD/MM/YYYY')
        : '-';

    const lastMileageKnownTemplate = event?.lastMileageKnown ? (
        <NumberFormat
            value={event?.lastMileageKnown}
            decimalSeparator="."
            thousandSeparator=" "
            displayType="text"
            suffix=" km"
        />
    ) : (
        '-'
    );

    const gearboxTemplate = event?.gearBox || '-';

    if (!tabEvents?.length) return null;

    const handleOpenCrvoInvoice = async () => {
        try {
            const crvoInvoice = await getStockCrvoInvoice(event?.registrationNumber, event?.vin);
            if (crvoInvoice) {
                saveAs(crvoInvoice, `crvo-invoice-${event?.registrationNumber}.pdf`);
            }
        } catch (error) {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        }
    };

    return (
        <div className="vehicle-datatable" data-vehicle-uuid={event?.vehicleUuid}>
            {!event?.vehicleUuid && !event?.registrationNumber && !event?.vin ? (
                <div className="vehicle-datatable__header">
                    <FormattedMessage
                        id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.LEAD.NO_VEHICLE"
                        values={{ count: event?.events?.length || 0 }}
                    />
                </div>
            ) : (
                <div className="vehicle-datatable__header">
                    <div className="vehicle-datatable__header__brandwrapper">
                        <div className="vehicle-datatable__header__image">
                            <img src={event?.image} alt={event?.brand || event?.vehicleUuid} />
                        </div>
                        <div className="vehicle-datatable__header__brand">
                            {KTUtil.isDesktopDevice() ? (
                                <dl>
                                    <dt>
                                        <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.MAKE" />
                                    </dt>
                                    <dd>{event?.brand || '-'}</dd>
                                    <dt>
                                        <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.MODEL" />
                                    </dt>
                                    <dd>{event?.model || '-'}</dd>
                                    <dt>
                                        <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.VERSION" />
                                    </dt>
                                    <dd>{event?.version || '-'}</dd>
                                </dl>
                            ) : (
                                <div>
                                    <h3 className="vehicle-datatable__header__brand__title">
                                        {event?.brand || '-'} &bull; {event?.model || '-'}
                                    </h3>
                                    <h4 className="vehicle-datatable__header__brand__subtitle">
                                        {event?.version || '-'}
                                    </h4>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="vehicle-datatable__header__info">
                        <div className="vehicle-datatable__header__info__registration">
                            {KTUtil.isDesktopDevice() ? (
                                <dl>
                                    <dt>
                                        <FormattedMessage id="CUSTOMERS.MODAL.VEHICLE_KNOW.IMMAT" />
                                    </dt>
                                    <dd>{event?.registrationNumber || '-'}</dd>
                                    <dt>
                                        <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.VIN" />
                                    </dt>
                                    <dd>{event?.vin || '-'}</dd>
                                    <dt>
                                        <FormattedMessage id="CUSTOMERS.MODAL.VEHICLE_KNOW.FUEL" />
                                    </dt>
                                    <dd>{event?.energy || '-'}</dd>
                                </dl>
                            ) : (
                                <div>
                                    <h3 className="vehicle-datatable__header__info__registration__title">
                                        {event?.registrationNumber || '-'} &bull; {event?.vin || '-'}
                                    </h3>
                                </div>
                            )}
                        </div>
                        <div className="vehicle-datatable__header__info__mileage">
                            {KTUtil.isDesktopDevice() ? (
                                <dl>
                                    <dt>
                                        <FormattedMessage id="CUSTOMERS.MODAL.VEHICLE_KNOW.MILEAGE" />
                                    </dt>
                                    <dd>{lastMileageKnownTemplate}</dd>
                                    <dt>
                                        <FormattedMessage id="CUSTOMERS.MODAL.VEHICLE_KNOW.MEC" />
                                    </dt>
                                    <dd>{mecTemplate}</dd>
                                    <dt>
                                        <FormattedMessage id="CUSTOMERS.MODAL.VEHICLE_KNOW.GEARBOX" />
                                    </dt>
                                    <dd>{gearboxTemplate}</dd>
                                </dl>
                            ) : (
                                <div>
                                    <h3 className="vehicle-datatable__header__info__mileage__title">
                                        {event?.energy || '-'} &bull; {lastMileageKnownTemplate} &bull; {mecTemplate}{' '}
                                        &bull; {gearboxTemplate}
                                    </h3>
                                </div>
                            )}
                        </div>
                        {event?.hasCRVOInvoice && (
                            <div className="vehicle-datatable__header__info__crvo">
                                <Button
                                    variant="outline-primary"
                                    className="mt-2 text-nowrap"
                                    onClick={handleOpenCrvoInvoice}
                                >
                                    <FormattedMessage id="CUSTOMER.MODAL.APVINSTORE.CRVO_BUTTON" />
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div className="vehicle-datatable__body">
                <LifeDataTable
                    events={tabEvents}
                    tab={tab}
                    activeTab={activeTab}
                    totalRecords={event?.events?.length}
                />
            </div>
        </div>
    );
};

export default VehicleDatatable;
