import React, { useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { generatePath, Link, useHistory, useParams } from 'react-router-dom';

import { RoleType, UserRole } from '@app/crud/autouser/role.types';

import { useAppSelector } from '@app/hooks';
import { formSubmit } from '@app/pages/autouser/roles/Form/Form.submit';
import ROUTES from '@app/router/Routes';
import { fetchRole, removeRole } from '@app/store/autouser/role/role.thunk';

import ButtonSelect from '@app/partials/content/ButtonSelect';
import Loader from '@app/partials/content/Loader';
import SwitchInput from '@app/partials/content/SwitchInput';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

const defaultValues = {
    name: '',
    shortName: '',
    description: '',
    type: RoleType.FONCTIONNEL,
    active: false,
    impersonate: false,
    visibleForDealership: false,
    visibleForDealershipsDirectory: false,
};

function Role() {
    const Intl = useIntl();
    const history = useHistory();
    const { reset, register, errors, handleSubmit } = useFormContext();
    const params = useParams<{ id: string }>();
    const dispatch = useDispatch();

    const newRole = typeof params.id === 'undefined';
    const { role, error, loading } = useAppSelector((state) => state.role);

    const typeOptions = [
        {
            value: RoleType.FONCTIONNEL,
            label: Intl.formatMessage({ id: 'AUTOUSER.ROLES.VIEW.TYPE.FUNCTIONAL' }),
            className: 'btn-primary',
        },
        { value: RoleType.SUPPORT, label: Intl.formatMessage({ id: 'AUTOUSER.ROLES.VIEW.TYPE.SUPPORT' }) },
    ];

    const onSubmit = (data: Omit<UserRole, 'id'>) => {
        formSubmit({
            newRole,
            data,
            dispatch,
            history,
            id: params.id,
        });
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    const deleteRole = () => {
        dispatch(
            removeRole({
                id: params.id,
                history,
            }),
        );
    };

    useEffect(() => {
        dispatch(fetchRole(params.id));

        return () => {
            reset(defaultValues);
        };
    }, [dispatch, reset, params.id]);

    useEffect(() => {
        reset({
            name: role?.name || defaultValues.name,
            shortName: role?.shortName || defaultValues.shortName,
            description: role?.description || defaultValues.description,
            type: role?.type || defaultValues.type,
            active: role?.active || defaultValues.active,
            impersonate: role?.impersonate || defaultValues.impersonate,
            visibleForDealership: role?.visibleForDealership || defaultValues.visibleForDealership,
            visibleForDealershipsDirectory:
                role?.visibleForDealershipsDirectory || defaultValues.visibleForDealershipsDirectory,
        });
    }, [reset, role]);

    useEffect(() => {
        if (error !== null) {
            toast({
                onShow: () => history.push(generatePath(ROUTES.ROLES.PATH)),
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        }
    }, [history, error]);

    return (
        <Form className="role" onSubmit={handleSubmit(onSubmit, onError)}>
            {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            <Row className="mb-5">
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-robot" />
                            <FormattedMessage id="AUTOUSER.ROLES.VIEW.ROLE" />
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col lg={8}>
                                    <Form.Group controlId="name">
                                        <Form.Label>
                                            <FormattedMessage id="AUTOUSER.ROLES.VIEW.NAME" />
                                        </Form.Label>
                                        <Form.Control
                                            name="name"
                                            type="text"
                                            placeholder={Intl.formatMessage({
                                                id: 'AUTOUSER.ROLES.VIEW.NAME.PLACEHOLDER',
                                            })}
                                            className={`${errors.name ? 'is-invalid' : ''}`}
                                            ref={register({
                                                required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                                minLength: 3,
                                            })}
                                        />
                                        <ErrorForm errors={errors} name="name" />
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group controlId="shortName">
                                        <Form.Label>
                                            <FormattedMessage id="AUTOUSER.ROLES.VIEW.SHORTNAME" />
                                        </Form.Label>
                                        <Form.Control
                                            name="shortName"
                                            type="text"
                                            className={`${errors.name ? 'is-invalid' : ''}`}
                                            ref={register({
                                                required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                            })}
                                        />
                                        <ErrorForm errors={errors} name="name" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group controlId="description">
                                <Form.Label>
                                    <FormattedMessage id="AUTOUSER.ROLES.VIEW.DESCRIPTION" />
                                </Form.Label>
                                <Form.Control
                                    name="description"
                                    type="text"
                                    className={`${errors.description ? 'is-invalid' : ''}`}
                                    ref={register}
                                />
                                <ErrorForm errors={errors} name="description" />
                            </Form.Group>
                            <Row className="d-flex justify-content-between">
                                <Col lg={2}>
                                    <Form.Group>
                                        <Form.Label>
                                            <FormattedMessage id="AUTOUSER.ROLES.VIEW.TYPE" />
                                        </Form.Label>
                                        <ButtonSelect options={typeOptions} name="type" />
                                    </Form.Group>
                                </Col>
                                <Col lg={2}>
                                    <Form.Group>
                                        <Form.Label>
                                            <FormattedMessage id="AUTOUSER.ROLES.VIEW.ACTIVE" />
                                        </Form.Label>
                                        <SwitchInput name="active" />
                                    </Form.Group>
                                </Col>
                                <Col lg={2}>
                                    <Form.Group>
                                        <Form.Label>
                                            <FormattedMessage id="AUTOUSER.ROLES.VIEW.IMPERSONATE" />
                                        </Form.Label>
                                        <SwitchInput
                                            name="impersonate"
                                            labelFirst={Intl.formatMessage({ id: 'TRANSLATOR.NO' })}
                                            labelSecond={Intl.formatMessage({ id: 'TRANSLATOR.YES' })}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={3}>
                                    <Form.Group>
                                        <Form.Label>
                                            <FormattedMessage id="AUTOUSER.ROLES.VIEW.VISIBLE_FOR_DEALERSHIP" />
                                        </Form.Label>
                                        <SwitchInput
                                            name="visibleForDealership"
                                            labelFirst={Intl.formatMessage({ id: 'TRANSLATOR.NO' })}
                                            labelSecond={Intl.formatMessage({ id: 'TRANSLATOR.YES' })}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={2}>
                                    <Form.Group>
                                        <Form.Label>
                                            <FormattedMessage id="AUTOUSER.ROLES.VIEW.VISIBLE_FOR_DEALERSHIPS_DIRECTORY" />
                                        </Form.Label>
                                        <SwitchInput
                                            name="visibleForDealershipsDirectory"
                                            labelFirst={Intl.formatMessage({ id: 'TRANSLATOR.NO' })}
                                            labelSecond={Intl.formatMessage({ id: 'TRANSLATOR.YES' })}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className={newRole ? 'text-center' : 'text-right'}>
                                    <Link to="/autouser/roles">
                                        <Button variant="outline-primary">
                                            <FormattedMessage id="TRANSLATOR.BACK" />
                                        </Button>
                                    </Link>
                                    <Button variant="primary" className="ml-2" type="submit">
                                        <FormattedMessage id="TRANSLATOR.SAVE" />
                                    </Button>
                                </Col>
                                {!newRole && (
                                    <Col className="text-right">
                                        <Button variant="outline-danger" onClick={() => deleteRole()}>
                                            <FormattedMessage id="TRANSLATOR.REMOVE" />
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Form>
    );
}

export default Role;
