import { saveAs } from 'file-saver';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';

import { empty } from '@app/helpers/ToolsHelper';

import { getStockCrvoInvoice } from '@app/crud/stock/stock.crud';

import { useAppSelector } from '@app/hooks';
import { isPublishAutosphere } from '@app/pages/stock/helpers/StockRules';
import StockModalTimeLine from '@app/pages/stock/modals/StockModalTimeLine';
import PublishedLabel from '@app/pages/stock/partials/PublishedLabel';
import UnPublishedLabel from '@app/pages/stock/partials/UnPublishedLabel';
import { Vehicle } from '@app/store/stock/stock.type';

import Label from '@app/partials/content/Label';
import OverlayTooltip from '@app/partials/content/OverlayTooltip';
import Permission from '@app/partials/content/Permission';
import toast from '@app/partials/content/Toast';
import { getPublishDays } from '@app/partials/content/Vehicle/VehicleHelpers';

interface StockVehiclesPublicationStatusColumnProps {
    vehicle: Vehicle;
}
export default function StockVehiclesPublicationStatusColumn({ vehicle }: StockVehiclesPublicationStatusColumnProps) {
    const Intl = useIntl();

    const [show, setShowModal] = useState(false);
    const isContextEFB = useAppSelector((state) => state.auth.userData.context === 'efb');

    const handleOpenCrvoInvoice = async () => {
        try {
            const crvoInvoice = await getStockCrvoInvoice(vehicle?.license_number, vehicle?.serial_number);
            if (crvoInvoice) {
                saveAs(crvoInvoice, vehicle?.crvo_invoice);
            }
        } catch (error) {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        }
    };

    const publishTagHandler = () => {
        if (isPublishAutosphere(vehicle) && vehicle.is_booked) {
            return (
                <div>
                    <PublishedLabel />
                    <div className="text-warning">
                        <b>.</b>&nbsp;{Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.IN_PROCESS_BOOKING_FW' })}
                    </div>
                </div>
            );
        } else if (isPublishAutosphere(vehicle, isContextEFB) && !vehicle.is_booked) {
            return (
                <OverlayTooltip
                    label={`STOCK_VEHICLES.TABLE.PUBLICATION_TOOLTIP${isContextEFB ? '_ABE' : ''}`}
                    id="publication"
                >
                    <Label variant="success" className="font-weight-bold">
                        {Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.PUBLISHED' })}
                        &nbsp; ({getPublishDays(vehicle)}
                        &nbsp;
                        {Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.DAYS' })})
                    </Label>
                </OverlayTooltip>
            );
        }

        return (
            <>
                <UnPublishedLabel variant="danger-lite" vehicle={vehicle} />
                <div className="d-flex flex-column">
                    {!empty(vehicle?.anomaly) &&
                        vehicle.anomaly.split(',').map((anomaly) => (
                            <span className="text-danger-lite" key={`vehicle-${vehicle?.sku}-anomaly-${anomaly}`}>
                                .{' '}
                                {anomaly.includes('Prix') && Number.isInteger(vehicle.price) && vehicle.price === 0
                                    ? Intl.formatMessage({
                                          id: 'STOCK_VEHICLES.TABLE.REASON.NO_PUBLICATION_ZERO_PRICE',
                                      })
                                    : anomaly}
                            </span>
                        ))}
                </div>
            </>
        );
    };

    return (
        <>
            <Row>
                <Col lg={12}>{publishTagHandler()}</Col>
            </Row>
            {vehicle.crvo && (
                <Permission permissions={['STOCK_VIEW_CRVO']}>
                    <Row className="mt-2">
                        <Col lg={12}>
                            <Button
                                variant="primary-gray"
                                className="rounded-0 mb-2 py-1"
                                onClick={() => setShowModal(true)}
                            >
                                <img alt="crvo" src="/media/icons/CRVO-blanc.svg" />
                            </Button>
                        </Col>

                        {vehicle.crvo.description && (
                            <Col lg={12}>
                                <b>.</b>&nbsp;{vehicle.crvo.description}
                            </Col>
                        )}
                        <StockModalTimeLine
                            events={vehicle?.crvo?.events}
                            show={show}
                            setShowModal={setShowModal}
                            title={
                                <FormattedHTMLMessage
                                    id="STOCK_VEHICLE.MODAL.TIMELINE.CRVO.TITLE"
                                    values={{
                                        licenseNumber: vehicle?.license_number,
                                        crvoName: vehicle?.crvo?.site_name,
                                    }}
                                />
                            }
                        />
                    </Row>
                </Permission>
            )}
            {vehicle?.crvo_invoice && (vehicle?.license_number || vehicle?.serial_number) && (
                <Row className="mt-2">
                    <Col lg={12}>
                        <button
                            type="submit"
                            className="text-primary text-left font-size-sm font-weight-bold btn p-0"
                            onClick={handleOpenCrvoInvoice}
                        >
                            <u>
                                <FormattedMessage id="STOCK_VEHICLES.TABLE.PUBLICATION_CRVO_INVOICE" />
                            </u>
                        </button>
                    </Col>
                </Row>
            )}
        </>
    );
}
