import classNames from 'classnames';
import dayjs from 'dayjs';
import { Column } from 'primereact/column';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';
import usePermissions from '@app/hooks/usePermissions';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { routeTo } from '@app/helpers/RoutesHelper';

import { OrderType } from '@app/crud/apv/order.type';

import ROUTES from '@app/router/Routes';
import { fetchOrders } from '@app/store/apv/order/order.thunk';

import CalendarRangeFilter from '@app/partials/content/CalendarRangeFilter';
import DropdownFilter from '@app/partials/content/DropdownFilter';
import FiltersProvider from '@app/partials/content/FiltersProvider';
import HoCDataTable from '@app/partials/content/HoCDataTable';
import InputFilter from '@app/partials/content/InputFilter';
import Label from '@app/partials/content/Label';
import RefreshTable from '@app/partials/content/RefreshTable';
import SearchInputFilter from '@app/partials/content/SearchInputFilter';
import { Filters } from '@app/partials/layout/Filters';

import { getStateBySlugApvInStore } from '../apvinstore/Helpers/statut';
import { getStateBySlug } from './Helpers/OrderStatus';

const OrderTable = ({ type }) => {
    const intl = useIntl();
    const { hasPermission } = usePermissions();
    const isOrderDesk = type === OrderType.ORDER_DESK;
    const { orders, totalRecords, filtersContent, loading } = useSelector((state) => state.order);
    const { items, start, filters, sortField, sortOrder } = useFilters({
        name: `apv_orders_${type}`,
    });
    const { handleFilter, handlePagination, handleSort, refresh } = useTableHandlers({
        filterStore: `apv_orders_${type}`,
        fetchAction: fetchOrders,
        paramsInterceptor: (params, filters) => ({
            ...params,
            start: filters.start,
            items: filters.items,
            orderType: type,
            orderBy: 'o.updated_DESC',
        }),
    });
    const selectStatusDropdown = () => (
        <DropdownFilter
            optionLabel="name"
            filterBy="name"
            name="statusId"
            options={
                filtersContent?.orderStatus &&
                Object.values(filtersContent.orderStatus).map((status) => ({
                    name: status.name,
                    key: status.id,
                }))
            }
            onChange={handleFilter}
        />
    );
    const selectApvInStoreOrderStatusDropdown = () => (
        <DropdownFilter
            optionLabel="name"
            filterBy="name"
            name="statusAppointmentApvInStore"
            options={
                filtersContent?.orderAppointmentStatusApvInStore &&
                Object.values(filtersContent.orderAppointmentStatusApvInStore).map((status) => ({
                    name: status.name,
                    key: status.slug,
                }))
            }
            onChange={handleFilter}
        />
    );

    const selectDealershipDropdown = () => (
        <DropdownFilter
            optionLabel="name"
            filterBy="name"
            name="dealershipId"
            options={
                filtersContent?.orderDealerships &&
                Object.values(filtersContent.orderDealerships).map((dealership) => ({
                    name: dealership.name,
                    key: dealership.id,
                }))
            }
            onChange={handleFilter}
        />
    );

    const inputFilter = (name) => <InputFilter name={name} onChange={handleFilter} />;

    const calendarRangeFilter = (start, end) => <CalendarRangeFilter start={start} end={end} onChange={handleFilter} />;

    const vehicleBodyTemplate = (order) => (
        <>
            {order.make} {order.model}
            <br />
            {dayjs(order.dateFirstRegistration).format('YYYY')}
            {` . `}
            <NumberFormat value={order.mileage} thousandSeparator=" " displayType="text" suffix=" km" />
        </>
    );

    const totalBodyTemplate = (order) => {
        return order.totalOrderAmount > 0 ? (
            <NumberFormat
                value={order.totalOrderAmount}
                thousandSeparator=" "
                decimalSeparator=","
                displayType="text"
                suffix=" €"
            />
        ) : (
            <span>{intl.formatMessage({ id: 'CUSTOMERS.MODAL.APVINSTORE.SUMMARY.FREE_PACKAGE' })}</span>
        );
    };

    const dealershipBodyTemplate = (order) => {
        const title = order?.dealership?.dealershipSource?.usual_name || null;
        return (
            <>
                <span className={classNames({ 'mr-3': title })}>{title}</span>
                {order?.dealership?.sendToCall && !isOrderDesk && (
                    <Label variant="primary">
                        <i className="mr-2 fas fa-headphones" />
                        {intl.messages['DATATABLE.DEALERSHIP_CALL']}
                    </Label>
                )}
            </>
        );
    };

    const statusBodyTemplate = (order) =>
        order.orderStatus && <Label variant={getStateBySlug(order)}>{order.orderStatus.name}</Label>;

    const statusOrderBodyTemplate = (order) =>
        order.appointment && (
            <Label variant={getStateBySlugApvInStore(order.appointment.orderAppointmentStatusApvInStore.slug)}>
                {order.appointment.orderAppointmentStatusApvInStore.name}
            </Label>
        );

    const appointmentBodyTemplate = (order) =>
        order?.appointment?.dateStart ? dayjs(order.appointment.dateStart).format('DD/MM/YYYY [à] HH [h] mm') : '';

    const createdBodyTemplate = (order) => dayjs(order.created).format('DD/MM/YYYY [à] HH [h] mm');

    const getRoutePath = (order) => {
        if (order.orderStatusApvInStore !== null) {
            return routeTo(ROUTES.APV_INSTORE_DETAIL.PATH, { id: order.id });
        }
        return routeTo(ROUTES.ORDER.PATH, { id: order.id });
    };

    const detailsBodyTemplate = (order) => (
        <Link to={getRoutePath(order)}>
            <Button className="p-2 px-3" variant="secondary">
                {intl.messages['DATATABLE.DEALERSHIP_BUTTON_DETAILS']}
            </Button>
        </Link>
    );

    const cols = [
        {
            field: 'reference',
            header: intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.REFERENCE' }),
            style: { width: '8%' },
            filter: true,
            filterElement: inputFilter('reference'),
        },
        {
            field: 'customer',
            header: intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.CUSTOMER' }),
            style: { width: '10%' },
            filter: true,
            filterElement: inputFilter('customer'),
            body: (order) => `${order?.firstname || ''} ${order?.lastname || ''}`,
        },
        {
            field: 'vehicleName',
            header: intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.VEHICLE' }),
            style: { width: '12%' },
            filter: true,
            filterElement: inputFilter('vehicleName'),
            body: vehicleBodyTemplate,
        },
        {
            field: 'total',
            header: intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.TOTAL' }),
            style: { width: '8%' },
            body: totalBodyTemplate,
        },
        {
            field: 'dealership',
            header: intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.DEALERSHIP' }),
            style: { width: '15%' },
            filter: true,
            filterElement: selectDealershipDropdown(),
            body: dealershipBodyTemplate,
        },
        {
            field: 'status',
            header: intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.STATUS' }),
            style: { width: '12%' },
            filter: true,
            filterElement: isOrderDesk ? selectApvInStoreOrderStatusDropdown() : selectStatusDropdown(),
            body: isOrderDesk ? statusOrderBodyTemplate : statusBodyTemplate,
        },
        {
            field: 'appointment_date',
            header: intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.APPOINTMENT' }),
            style: { width: '10%' },
            filter: true,
            filterElement: calendarRangeFilter('dateStartAppointment', 'dateEndAppointment'),
            body: appointmentBodyTemplate,
        },
        {
            field: 'order_date',
            header: intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.ORDER' }),
            style: { width: '10%' },
            filter: true,
            filterElement: calendarRangeFilter('dateStartOrder', 'dateEndOrder'),
            body: createdBodyTemplate,
        },
    ];

    if (hasPermission('APV_DETAILS_ORDER')) {
        cols.push({
            field: 'details',
            header: intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.DETAILS' }),
            style: { width: '5%' },
            filter: false,
            body: detailsBodyTemplate,
        });
    }

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <FiltersProvider value={filters}>
            <Filters>
                <Row>
                    <RefreshTable count={totalRecords[type]} onClick={refresh} />
                    <Col lg={5} offset={2}>
                        <SearchInputFilter
                            onChange={handleFilter}
                            name="research"
                            placeholder={intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.DETAILS' })}
                        />
                    </Col>
                </Row>
            </Filters>
            <HoCDataTable
                value={orders}
                totalRecords={totalRecords[type]}
                lazy
                loading={loading}
                paginator
                onFilter={handleFilter}
                onPage={handlePagination}
                onSort={handleSort}
                paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                currentPageReportTemplate={intl.messages['DATATABLE.REPORT_TEMPLATE']}
                rows={items}
                first={start}
                sortField={sortField}
                sortOrder={sortOrder}
                removableSort
                filterDisplay="row"
                rowsPerPageOptions={[25, 50, 100]}
                emptyMessage={intl.messages['DATATABLE.EMPTY_RESULT']}
            >
                {dynamicColumns}
            </HoCDataTable>
        </FiltersProvider>
    );
};

export default OrderTable;
