import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useFetch from '@app/hooks/useFetch';

import { routeTo } from '@app/helpers/RoutesHelper';

import { getUserPerimeter } from '@app/crud/autouser/user.crud';

import { useAppDispatch, useAppSelector, usePermissions } from '@app/hooks';
import { DealershipTreeSelectNode, updateDatas } from '@app/pages/autouser/users/Helpers/Dealership';
import StockTreeSelect from '@app/pages/stock/partials/StockTreeSelect';
import ROUTES from '@app/router/Routes';
import { actions as modalActions } from '@app/store/modal/modal.store';

import AskPricingButton from '@app/partials/content/Pricing/AskPricingButton/AskPricingButton';
import SearchInputFilter from '@app/partials/content/SearchInputFilter';

type StockPricingToDoHeaderProps = {
    handleFilter: Function;
    updatePendingFilters: Function;
    releasePendingFilters: Function;
    setLoadedPerimeter: (value: any) => void;
    isHistory: boolean;
    timeRemaining: {
        distance: number;
        days: number;
        hours: number;
        minutes: number;
        seconds: number;
    };
    page: string;
};

const StockPricingToDoHeader = ({
    handleFilter,
    updatePendingFilters,
    releasePendingFilters,
    setLoadedPerimeter,
    isHistory,
    timeRemaining,
    page,
}: StockPricingToDoHeaderProps) => {
    const { userData } = useAppSelector((state) => state.auth);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { hasPermission } = usePermissions();

    const { vehiclesSelected } = useAppSelector((state) => state.stock);
    // @ts-ignore
    const vehiclesList = Object.keys(vehiclesSelected).filter((v) => vehiclesSelected[v] !== undefined);
    const disableListingActions = vehiclesList?.length < 2 || vehiclesList?.length > 10;

    const { data: perimeter } = useFetch({
        // @ts-ignore
        fetchAction: getUserPerimeter,
        resultInterceptor: (response: DealershipTreeSelectNode[]) => {
            setLoadedPerimeter(true);
            return updateDatas(response);
        },
        axios: false,
        autoFetch: true,
    });

    return (
        !['conseiller-commercial'].includes(userData?.role?.slug) && (
            <div className="mt-5 mb-5 d-flex flex-wrap align-items-center">
                {hasPermission('STOCK_MANUAL_PRICING') && <AskPricingButton />}
                <div className="mr-5 flex-grow-1" style={{ maxWidth: '40%' }}>
                    {/* @ts-ignore */}
                    <StockTreeSelect
                        perimeter={perimeter}
                        updatePendingFilters={updatePendingFilters}
                        releasePendingFilters={releasePendingFilters}
                    />
                </div>
                <SearchInputFilter
                    className="flex-grow-1"
                    onChange={handleFilter}
                    name="keyword"
                    inputClassName="m-0"
                    placeholder={Intl.formatMessage({
                        id: 'STOCK_VEHICLES.FILTERS.SEARCH_INPUT.PLACEHOLDER',
                    })}
                />
                <Button
                    variant="outline-lighten"
                    className="border-light-light text-secondary"
                    style={{ borderRadius: '3px' }}
                    onClick={() =>
                        history.push(
                            routeTo(ROUTES.STOCK_PRICING_TO_DO.PATH, {
                                page: isHistory ? 'list' : 'history',
                            }),
                        )
                    }
                >
                    {isHistory ? (
                        <>
                            <i className="las la-arrow-left" />
                            {Intl.formatMessage({
                                id: 'STOCK.PRICING.SHOW.BUTTON.TODO',
                            })}
                        </>
                    ) : (
                        <>
                            <i className="las la-history" />
                            {Intl.formatMessage({
                                id: 'STOCK.PRICING.SHOW.BUTTON.HISTORY',
                            })}
                        </>
                    )}
                </Button>
                {!isHistory && (timeRemaining?.distance > 0 || hasPermission('STOCK_CHECK_PRICING')) && (
                    <>
                        <Button
                            variant="success"
                            className="ml-3"
                            disabled={disableListingActions}
                            onClick={() => {
                                dispatch(
                                    modalActions.modalChange('pricing_confirm', {
                                        vehicles: {
                                            ...vehiclesSelected,
                                        },
                                        checkMode: page === 'check',
                                    }),
                                );
                            }}
                        >
                            <FormattedMessage id="STOCK.PRICING.ACTIONS.CONFIRM" />
                        </Button>
                        <Button
                            variant="primary"
                            className="ml-3"
                            disabled={disableListingActions}
                            onClick={() => {
                                dispatch(
                                    modalActions.modalChange('pricing_modify', {
                                        vehicles: {
                                            ...vehiclesSelected,
                                        },
                                        checkMode: page === 'check',
                                    }),
                                );
                            }}
                        >
                            <FormattedMessage id="STOCK.PRICING.ACTIONS.MODIFY" />
                        </Button>
                        {page !== 'check' && (
                            <Button
                                variant="danger"
                                className="ml-3"
                                disabled={disableListingActions}
                                onClick={() => {
                                    dispatch(
                                        modalActions.modalChange('pricing_cancel', {
                                            vehicles: {
                                                ...vehiclesSelected,
                                            },
                                            checkMode: page === 'check',
                                        }),
                                    );
                                }}
                            >
                                <FormattedMessage id="STOCK.PRICING.ACTIONS.CANCEL" />
                            </Button>
                        )}
                    </>
                )}
            </div>
        )
    );
};

export default StockPricingToDoHeader;
